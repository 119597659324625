import React from "react"
import Layout from "../components/layout"
import Projects from "../components/Projects"
// import Header from "./header"

export default ({ pageContext, props, location }) => (
  <Layout>
    {console.log(pageContext.content)}
    <div dangerouslySetInnerHTML={{ __html: pageContext.content }} />
    <Projects path={location.pathname} />
  </Layout>
)
